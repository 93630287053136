import axios from 'axios'
import urls from './http/url'
import {
  Message,
  Loading
} from 'element-ui'
// import browser from '../common/browser'
//免登录接口
const noLoginUrl = [
  '/addons/cms/api.common/init',
  '/addons/cms/api.ems/send',
  '/api/ems/send',
  '/addons/cms/api.sms/send',
  '/addons/cms/api.archives/index',
  '/addons/cms/api.archives/detail',
  '/addons/cms/api.login/login',
  '/addons/cms/api.login/mobilelogin',
  '/addons/cms/api.login/register',
  '/addons/cms/api.login/resetpwd',
  '/addons/cms/api.login/wxLogin',
  '/addons/cms/api.login/appLogin',
  '/addons/cms/api.login/getWechatMobile',
  '/addons/cms/api.my/aboutus',
  '/addons/cms/api.my/agreement',
  '/addons/third/api/getAuthUrl',
  '/addons/third/api/callback',
  '/addons/third/api/account',
  '/addons/cms/api.search/index',
  '/addons/cms/api.tag/index',
  '/addons/cms/api.common/getCategory',
  '/addons/cms/api.user/getSigned',
  '/addons/cms/api.user/userInfo',
  '/addons/cms/api.comment/index',
  '/addons/vip/api.index/index',
  '/addons/cms/api.diyform/formList',
  '/addons/cms/api.diyform/show',
  '/addons/cms/api.login/statistical',
  '/index.php/addons/cms/api.my/getpages',
  '/index.php/addons/cms/api.common/getblocklist',
];
// url截取
const getCaption = function (url) {
  let reg = urls.baseUrl;
  url = url.replace(reg, '');
  return url;
}

//设置session_id
const getSessionId = function (vm) {
  let session = vm.$util.getDb('session');
  if (!session) {
    // console.log('session',vm.$u)
    let guid = vm.$u.guid();
    vm.$util.setDb('session', guid);
    return guid;
  }
  return session;
}

// 创建 axios 实例
let http = axios.create({
  // headers: {'Content-Type': 'application/json'},
  timeout: 60000
})

// 设置 post、put 默认 Content-Type
http.defaults.headers.post['Content-Type'] = 'application/json'
http.defaults.headers.put['Content-Type'] = 'application/json'

// 添加请求拦截器
http.interceptors.request.use(config => {
  console.log('vue实例', vm)
  let url = getCaption(config.url);
  // console.log(url)
  if (url.indexOf('?') != -1) {
    let arr = url.split('?');
    url = arr[0];
  }
  if (noLoginUrl.indexOf(url) == -1 && ! vm.$store.state.vuex_token) {
    // if (noLoginUrl.indexOf(url) == -1) {
    console.log('需要登录的接口')
    vm.$router.push({
      path: '/login'
    });
    return false;
  }
  config.headers.token = vm.$store.state.vuex_token;
  config.headers.sid = 'caMwllmPZB4zeinPYdcAJxTRMcwpDcrx';
  // config.headers.sid = getSessionId(vm);
  config.headers.uid = vm.$store.state.vuex_user.id || 0;
  config.headers['x-requested-with'] = 'xmlhttprequest';
  // console.log('执行到当前',config.method)
  if (config.method === 'post' || config.method === 'put') {
    // post、put 提交时，将对象转换为string, 为处理Java后台解析问题 
    // config.data = JSON.stringify(config.data)
    config.data['__token__'] = vm.$store.state.vuex__token__;
  } else if (config.method === 'get') {
    // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
    let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
    config.url += symbol + '_=' + Date.now()
  }
  // 请求发送前进行处理
  return config
}, error => {
  // 请求错误处理
  console.log('请求错误')
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(res => {
  //返回__token__,设置
  if (res.headers.__token__) {
    vm.$store.state.vuex__token__ = res.headers.__token__;
  }
  let result = res.data;
  if (result.data && result.data.__token__) {
    vm.$store.state.vuex__token__  =result.data.__token__;
  }
  switch (result.code) {
    case 1:
    case 0:
      return result;
      break;
    case 401:
      //需要登录的接口，当token 过期时，到登录页面
      vm.$store.state.vuex_token = '';
      vm.$router.push({
        path: '/login'
      });
      return result;
      break;
    case 403: //没有权限访问
      Message({
        message: result.msg,
        type: 'error'
      })
      return result;
      break;
    default:
      if (res.statusCode == 200) {
        return res.data;
      } else {
        console.error(res)
        Message({
          message: 'Network Error',
          type: 'error'
        })
      }
  }
  // let {data} = res
  // return data
}, error => {
  console.log(error)
  let info = {}
  let res = error.res
  // let {status, statusText, data} = error.res
  if (!error.res) {
    Message({
      message: 'Network Error',
      type: 'error'
    })
    info = {
      code: 5000,
      msg: 'Network Error'
    }
  } else {
    Message({
      message: res.statusText,
      type: 'error'
    })
    // 此处整理错误信息格式
    info = {
      code: res.status,
      data: res.data,
      msg: res.statusText
    }
  }
  return Promise.reject(info)
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
  return http
}
