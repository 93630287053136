import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: index,
    redirect: 'Home',
    children: [{
        path: 'Home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        //动态设置title与metad的类metaInfo
        meta: {
          metaInfo: {
            title: "个人数据库",
            keywords: "PKU-REOD",
            description: "首页"
          }
        }
      },
      {
        path: 'manual',
        name: 'manual',
        component: () => import('../views/manual.vue')
      },
      {
        path: 'database',
        name: 'database',
        component: () => import('../views/database.vue')
      },
      {
        path: 'blogs',
        name: 'blogs',
        component: () => import('../views/blogs.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/About.vue')
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import('../views/detail.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('../views/search.vue')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/login.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('../views/register.vue')
      },
      {
        path: 'forgetpwd',
        name: 'forgetpwd',
        component: () => import('../views/forgetpwd.vue')
      },
      {
        path: 'my',
        name: 'my',
        redirect: 'my/myCollection',
        component: () => import('../views/my.vue'),
        children: [{
            path: 'myCollection',
            name: 'myCollection',
            component: () => import('../views/myCollection.vue')
          },
          {
            path: 'myComments',
            name: 'myComments',
            component: () => import('../views/myComments.vue')
          },
          {
            path: 'myMessage',
            name: 'myMessage',
            component: () => import('../views/myMessage.vue')
          },
          {
            path: 'myMessageInfo',
            name: 'myMessageInfo',
            component: () => import('../views/myMessageInfo.vue')
          },
          {
            path: 'changePswd',
            name: 'changePswd',
            component: () => import('../views/changePswd.vue')
          },
          {
            path: 'mySubmit',
            name: 'mySubmit',
            component: () => import('../views/mySubmit.vue')
          },
          {
            path: 'mySublist',
            name: 'mySublist',
            component: () => import('../views/mySublist.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//全局路由前置守卫
router.beforeEach((to, from, next) => {
  //保存当前页面路径
  // console.log('全局前置守卫', to, from)
  // 设置页面title
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  let query = to.query;
  let qr = [],
    q = '';
  for (let i in query) {
    qr.push(i + '=' + query[i]);
  }
  if (qr.length) {
    q = '?' + qr.join('&');
  }
  store.commit('$localStore', {
    name: 'vuex_route',
    value: to.path + q
  });
  let state = store.state;
  for (let name in state) {
    // console.log('在这里存储', name, state[name])
    let value = state[name]
    store.commit('$localStore', {
      name,
      value
    })
  }
  //去的页面是登录类型，来的页面不是登录类型，就缓存此页面，排除用户协议
  if (to.path.indexOf('/login') != -1 && from.path.indexOf('/login') == -1 && from.path.indexOf('agreement') == -1) {
    localStorage.setItem('last_page', {
      path: from.path,
      query: from.query
    });
  }
  if (to.path.indexOf('/my/') != -1 && !state.vuex_token) {
    console.log('是只有登录才显示的页面但是没有token')
    router.replace({
      path: '/'
    })
  }
  next();
})
// 全局路由后置守卫
router.afterEach((to, from) => {
  // console.log('全局后置守卫', to, from)
})

export default router
