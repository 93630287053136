import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import api from './api/install'
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import Meta from 'vue-meta'
import VueI18n from 'vue-i18n'
import * as Util from '@/common/util.js'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// Vue.prototype.$axios = axios
Vue.config.productionTip = false

//原型追加工具函数
Vue.prototype.$util = Util;

Vue.use(Element)
Vue.use(api)

// 考虑规范性,使用 module.exports 导出,应该使用 require 引用
const Chinese = require('./static/locales/zh-CN.js')
const English = require('./static/locales/en-US.js')

// 引入语言包
Vue.use(VueI18n)

// 获取本地语言存储，刷新后初始化页面默认语言
const locale = localStorage.getItem('locale');
const i18n = new VueI18n({
  // 默认语言
  locale: locale?locale:'en-US',
  // 引入语言文件
  messages: {
    'zh-CN': Chinese,
    'en-US': English
  }
})
Vue.prototype._i18n = i18n

// 混入i18n计算
const i18nMixin = require('@/common/i18n.mixin.js')
console.log('混入')
console.log(i18nMixin)
Vue.mixin(i18nMixin)

//动态设置title与meta
Vue.use(Meta)

router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo)
      store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
  next()
});

window.vm = new Vue({
  router,
  i18n,
  store,
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [{
        name: "keywords",
        content: this.$store.state.metaInfo.keywords
      }, {
        name: "description",
        content: this.$store.state.metaInfo.description
      }]
    }
  },
  render: h => h(App)
}).$mount('#app')
