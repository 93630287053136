import api from '../index'
import urls from './url'
// import { formatUrl } from '../../common/utils'
const header = {
  'Authorization': 'Client-ID xxxxxxx'
}
// console.log(urls)
export default {
//   getArchives(params) {
//     // return出去了一个promise
//     // return api.get(urls.photos, params, header)
//     return api.get(urls.baseUrl + '/addons/cms/api.archives/index', params, header)
//   },
  getConfig(params) {return api.get(urls.baseUrl + '/addons/cms/api.common/init', params, header)},
  getEmsSend(params) {return api.post(urls.baseUrl + '/addons/cms/api.ems/send', params, header)},
  getSmsSend(params) {return api.post(urls.baseUrl + '/addons/cms/api.sms/send', params, header)},
  getEmailSend(params) {return api.post(urls.baseUrl + '/api/ems/send', params, header)},
  getArchives(params) {return api.get(urls.baseUrl + '/addons/cms/api.archives/index', params, header)},
  getArchivesDetail(params) {return api.get(urls.baseUrl + '/addons/cms/api.archives/detail', params, header)},
  getArchivesVote(params) {return api.post(urls.baseUrl + '/addons/cms/api.archives/vote', params, header)},
  getArchivesOrder(params) {return api.post(urls.baseUrl + '/addons/cms/api.archives/order', params, header)},
  getChannel(params) {return api.get(urls.baseUrl + '/addons/cms/api.archives/get_channel', params, header)},
  getChannelFields(params) {return api.get(urls.baseUrl + '/addons/cms/api.archives/get_channel_fields', params, header)},
  archivesPost(params) {return api.post(urls.baseUrl + '/addons/cms/api.archives/archives_post', params, header)},
  myArchives(params) {return api.get(urls.baseUrl + '/addons/cms/api.archives/my', params, header)},
  deleteArchives(params) {return api.post(urls.baseUrl + '/addons/cms/api.archives/delete', params, header)},
  getUserIndex(params) {return api.get(urls.baseUrl + '/addons/cms/api.user/index', params, header)},
  getUserProfile(params) {return api.post(urls.baseUrl + '/addons/cms/api.user/profile', params, header)},
  goUserLogout(params) {return api.get(urls.baseUrl + '/addons/cms/api.user/logout', params, header)},
  goUserAvatar(params) {return api.post(urls.baseUrl + '/addons/cms/api.user/avatar', params, header)},
  getUserInfo(params) {return api.post(urls.baseUrl + '/addons/cms/api.user/userInfo', params, header)},
  getMyComment(params) {return api.get(urls.baseUrl + '/addons/cms/api.my/comment', params, header)},
  getMyboutus(params) {return api.get(urls.baseUrl + '/addons/cms/api.my/aboutus', params, header)},
  getblocklist(params) {return api.get(urls.baseUrl + '/index.php/addons/cms/api.common/getblocklist', params, header)},
  getpages(params) {return api.get(urls.baseUrl + '/index.php/addons/cms/api.my/getpages', params, header)},
  getOrder(params) {return api.get(urls.baseUrl + '/addons/cms/api.my/order', params, header)},
  getMyagree(params) {return api.get(urls.baseUrl + '/addons/cms/api.my/agreement', params, header)},
  goCommentPost(params) {return api.post(urls.baseUrl + '/addons/cms/api.comment/post', params, header)},
  goCommentIndex(params) {return api.get(urls.baseUrl + '/addons/cms/api.comment/index', params, header)},
  goLogin(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/login', params, header)},
  mobilelogin(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/mobilelogin', params, header)},
  goRegister(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/register', params, header)},
  goResetpwd(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/resetpwd', params, header)},
  gowxLogin(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/wxLogin', params, header)},
  goAppLogin(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/appLogin', params, header)},
  getWechatMobile(params) {return api.post(urls.baseUrl + '/addons/cms/api.login/getWechatMobile', params, header)},
  getAuthUrl(params) {return api.get(urls.baseUrl + '/addons/third/api/getAuthUrl', params, header)},
  goAuthCallback(params) {return api.post(urls.baseUrl + '/addons/third/api/callback', params, header)},
  goThirdAccount(params) {return api.post(urls.baseUrl + '/addons/third/api/account', params, header)},
  getMoneyLogs(params) {return api.get(urls.baseUrl + '/addons/cms/api.the_logs/money', params, header)},
  getScoreLogs(params) {return api.get(urls.baseUrl + '/addons/cms/api.the_logs/score', params, header)},
  selectpage(params) {return api.get(urls.baseUrl + '/addons/cms/api.common/selectpage', params, header)},
  search(params) {return api.get(urls.baseUrl + '/addons/cms/api.search/index', params, header)},
  signinConfig(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/index', params, header)},
  monthSign(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/monthSign', params, header)},
  dosign(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/dosign', params, header)},
  fillup(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/fillup', params, header)},
  signRank(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/rank', params, header)},
  signLog(params) {return api.get(urls.baseUrl + '/addons/signin/api.index/signLog', params, header)},
  formField(params) {return api.get(urls.baseUrl + '/addons/cms/api.diyform/index', params, header)},
  postForm(params) {return api.post(urls.baseUrl + '/addons/cms/api.diyform/postForm', params, header)},
  formList(params) {return api.get(urls.baseUrl + '/addons/cms/api.diyform/formList', params, header)},
  formShow(params) {return api.get(urls.baseUrl + '/addons/cms/api.diyform/show', params, header)},
  tagIndex(params) {return api.get(urls.baseUrl + '/addons/cms/api.tag/index', params, header)},
  getCategory(params) {return api.get(urls.baseUrl + '/addons/cms/api.common/getCategory', params, header)},
  getSigned(params) {return api.post(urls.baseUrl + '/addons/cms/api.user/getSigned', params, header)},
  getVipIndex(params) {return api.get(urls.baseUrl + '/addons/vip/api.index/index', params, header)},
  goVipSubmit(params) {return api.get(urls.baseUrl + '/addons/vip/api.order/submit', params, header)},
  getCollection(params) {return api.get(urls.baseUrl + '/addons/cms/api.collection/index', params, header)},
  addCollection(params) {return api.post(urls.baseUrl + '/addons/cms/api.collection/create', params, header)},
  delCollection(params) {return api.post(urls.baseUrl + '/addons/cms/api.collection/delete', params, header)},
  delsCollection(params) {return api.post(urls.baseUrl + '/addons/cms/api.collection/deletes', params, header)},
  isCollection(params) {return api.post(urls.baseUrl + '/addons/cms/api.collection/whether_collection', params, header)},
  messageList(params) {return api.get(urls.baseUrl + '/api/message/getlist', params, header)},
  getUnreadCount(params) {return api.get(urls.baseUrl + '/api/message/getUnreadCount', params, header)},
  getMessageDetails(params) {return api.get(urls.baseUrl + '/api/message/getMessageDetails', params, header)},
  setMessageForRead(params) {return api.get(urls.baseUrl + '/api/message/setMessageForRead', params, header)},
  deletedMessage(params) {return api.get(urls.baseUrl + '/api/message/deletedMessage', params, header)},
  statistical(params) {return api.get(urls.baseUrl + '/addons/cms/api.login/statistical', params, header)}
}
