module.exports = {
	message: {
		index:{
			login:"登录",
			register:"注册",
			message:"通知",
			tabbar:["首页","用户说明","数据库","新闻","关于我们"]
		},
		footerBar:{
			title:"个人数据库 ",
			Abbreviation:"(PKU-REOD)",
			contentus:"联系方式",
			content:"8600 Rockville Pike, Bethesda, MD, 20894 USA",
			type:["Contact","Policies","FOIA"],
			unit:["国家医学图书馆","美国国立卫生研究院","卫生与公共服务部","美国政府"],
			fllow:"关注公共化学",
			terms:["","中国北京市海淀区颐和园路5号 100871","京ICP备05065075号-1","京公网安备110402430047号"]
		},
		my: {
			name:'我的',
			button: '立即登录',
			sentence: '欢迎来到我的!',
			profile: '个人资料',
			exit: '退出登录',
			message: '我的留言',
			comment: '我的评论',
			favorites: '我的收藏',
			aboutus: '关于我们',
			contact: '联系电话',
			messagenot: '消息通知',
			language: '语言切换',
			password: '修改密码',
			agreement: '用户协议',
			signout: '退出登录',
			introduction: '暂无介绍！',
			submit:'递交申请'
		},
		Home:{
			name:"首页",
			title1:"Explore Chemistry",
			title2:"PKU-REOD 快速查找信息",
			placehold:"请输入您需要搜索的内容关键词",
			blogs:"最新",
			seeall:"查看更多",
			learnmore:"详细了解 PKU-REOD"
		},
		manual:{
			name:"用户说明",
			title:"用户守则"
		},
		database:{
			name:"数据库",
			placehold:"请输入您需要搜索的内容关键词",
			seeall:"查看更多"
		},
		blogs:{
			name:"新闻"
		},
		about:{
			name:"关于我们",
			title1:"关于我们",
			title2:"当前研究兴趣",
			title3:"我们的团队"

		},
		search:{
			name:"搜索",
			placehold:"请输入您需要搜索的内容关键词",
			seeall:"查看更多",
			interest:"最感兴趣",
			nonetips: '很遗憾，没有找到您要找的内容',
		},
		myCollection: {
			name: "我的收藏",
			to: "收藏于",
			none: "您还没有收藏哦...",
			delbtn:"取消收藏",
			seeText:"查看原文"
		},
		myComments:{
			name:'我的评论',
			to:'评论于',
			none: '您还没有评论哦...',
			seeText:"查看原文"
		},
		myMessage: {
			name: '我的消息',
			header: '系统消息',
			read: '已读',
			noread: '未读',
			to:'发布时间',
			none: '您还没有消息哦...',
			tips: '该消息已读'
		},
		myMessageInfo: {
			name: '消息详情',
			to:'我的消息',
			time: '发布时间',
			delete: '删除消息'
		},
		mySubmit: {
			name: '递交申请',
			to:'支付提交列表',
			button: '提交',
			authorization: '授权申请',
			authorizatinfo: '为了让我们更快地与您取得联系，了解您的需求，满足您的要求，请您认真填写以下信息，确保信息准确无误后提交。',
			username: '姓名',
			usernametip: '请输入您的姓名，例如李一',
			telephone: '手机号码',
			telephonetip: '请输入您的电话号码',
			email: '邮箱',
			emailtip: '请输入您的邮箱',
			need: '需求',
			needtip: '请输入您的需求，例如获得阅读全文的权限。'
		},
		mySublist: {
			name: '支付提交列表',
			to:'递交申请',
			time:'提交时间',
			placeholder: '请输入关键字搜索'
		},
		login: {
			name: '登录',
			button: '登录',
			typephone: '手机登录',
			typeemail: '邮箱登录',
			phonetips: '请您输入手机号码',
			emailtips: '请输入您的邮箱',
			code: '获取验证码',
			codetips: '输入验证码',
			pswtips: '请您输入密码',
			agree: '我已经阅读并同意',
			agreement: '隐私政策和用户协议',
			agreetips: '请确认用户协议',
			forgetpw: '忘记密码',
			signup: '注册账号',
			verification: '验证失败',
			loginTips: '已有账号，',
			login: '马上登陆',
		},
		register: {
			name: '注册',
			button: '注册',
			typephone: '手机',
			typeemail: '邮箱',
			usernametips: '请填写用户昵称',
			phonetips: '请您输入手机号码',
			emailtips: '请输入您的邮箱',
			code: '获取验证码',
			changecode: '秒重新获取',
			endcode: '重新获取',
			codetips: '请输入验证码',
			pswtips: '请您输入密码',
			pswagaintips: '再次输入您的密码',
			pswnotips: '俩次密码输入不一致',
			emailnotips: '邮箱地址不正确！',
			phonenotips: '手机号码格式不正确！',
			agree: '我已经阅读并同意',
			agreement: '隐私政策和用户协议',
			agreetips: '请确认用户协议',
			loginTips: '已有账号，',
			login: '马上登陆',
			verification: '验证失败'
		},
		forgetpwd: {
			name: '忘记密码',
			button: '提交',
			typephone: '手机',
			typeemail: '邮箱',
			phonetips: '请您输入手机号码',
			emailtips: '请输入您的邮箱',
			code: '获取验证码',
			changecode: '秒重新获取',
			endcode: '重新获取',
			codetips: '请输入验证码',
			pswtips: '请您输入新密码',
			pswagaintips: '再次输入您的密码',
			pswnotips: '俩次密码输入不一致',
			emailnotips: '邮箱地址不正确！',
			phonenotips: '手机号码格式不正确！',
			verification: '验证失败',
			loginTips: '已有账号，',
			login: '马上登陆',
		},
		changePswd: {
			name: 'PKU-REOD修改密码',
			button: '提交',
			typephone: '手机',
			typeemail: '邮箱',
			phonetips: '请您输入手机号码',
			emailtips: '请输入您的邮箱',
			code: '获取验证码',
			changecode: '秒重新获取',
			endcode: '重新获取',
			codetips: '请输入验证码',
			pswtips: '请您输入新密码',
			pswagaintips: '再次输入您的密码',
			pswnotips: '俩次密码输入不一致',
			emailnotips: '邮箱地址不正确！',
			phonenotips: '手机号码格式不正确！',
			verification: '验证失败'
		},
		detail: {
			name: '详情',
			button: '立即登录',
			buttontip:'还没有登录，请先登录',
			applybtn:'递交申请',
			placehold:"请输入您需要搜索的内容关键词",
			toText:'当前位置',
			to:['DATABASE','blogs'],
			comment: '立即评论',
			tocomment: '发表评论',
			nocomment: '还没有评论，快来抢先吧！',
			commenttip: '请输入评论内容',
			commentlist: '评论列表',
			collection: '收藏',
			collected:'已收藏',
			readmore: '展开阅读全文',
			fulltext: '查看全文',
			loginfulltext: '登录查看全文',
			collecttip:'请先登录再操作！',
			npopup: {
				content: '您需要查看全文的信息是保密的，您需要向发件人提交申请以获得相应的权限。',
				button1: '电话咨询',
				button2: '支付',
			}
		},
		// 下面的不用
		tabBar: {
			home: '首页',
			collection: '收藏',
			news: '新闻',
			my: '我的',
			language: '当前语言',
			name: 'PKU-REOD'
		},
		cande: {
			button: '前往首页',
			introduce1: '个人数据库',
			introduce2: '有机化合物反应位点',
			introduce3: '数据库',
			tips: '请选择语言'
		},
		home: {
			name: '有机物活性位点数据库',
			labeltext: '最新资询',
			list: [{
				name: '多环芳烃',
				img: 'PAHs'
			}, {
				name: '内分泌干扰',
				img: 'EDCs'
			}, {
				name: '个人护理',
				img: 'PPCPs'
			}, {
				name: '烷基类物质',
				img: 'PFAS'
			}, {
				name: '消毒副产物',
				img: 'DBPs'
			}, {
				name: '其他',
				img: 'Other'
			}]
		},
		collection: {
			name: '收藏',
			labeltext: '最感兴趣',
			nonetips: '抱歉，您的收藏夹为空！',
			nonebg: './../../static/image/collection-none.png',
			createtip: '收藏于'
		},
		// search: {
		// 	name: '搜索',
		// 	placeholder: '请输入关键字搜索',
		// 	labeltext: '最感兴趣',
		// 	nonetips: '很遗憾，没有找到您要找的内容',
		// 	nonebg: './../../static/image/search-none.png',
		// },
		news: {
			name: '新闻',
			placeholder: '请输入关键字搜索',
		},
		// database: {
		// 	name: '数据库'
		// },
		profile: {
			name: '个人资料',
			button: '提交',
			avatar: '头像',
			username: '用户名',
			usernametip: '请输入您的用户名',
			nickname: '昵称',
			nicknametip: '请输入您的昵称',
			bio: '个人介绍',
			biotip: '请输入个人介绍',
			fail: '验证失败'
		},
		codelogin: {
			name: '登录',
			button: '登录',
			typephone: '手机登录',
			typeemail: '邮箱登录',
			phonetips: '请您输入手机号码',
			emailtips: '请输入您的邮箱',
			code: '获取验证码',
			codetips: '输入验证码',
			pswtips: '请您输入密码',
			agree: '我已经阅读并同意',
			agreement: '隐私政策和用户协议',
			forgetpw: '忘记密码'
		},
		agreement: {
			name: '用户协议'
		},
		// index: {
		// 	sentence: '欢迎来到首页!',
		// 	saoma: '扫码',
		// 	drawer: '抽屉',
		// 	judge: '判断',
		// 	yes: '是',
		// 	no: '否'
		// },
		loadtext: {
			loadmore: '轻轻上拉',
			loading: '努力加载中',
			nomore: '实在没有了'
		},
		popup: {
			open: '暂未开放!',
			tips: '提示',
			modal: '这是一个模态弹窗!',
			confirm: '确定',
			cancel: '取消',
			loading: '加载中...',
			exit: '退出',
			exittext: '确定退出登录？',
			version: '版本升级（双语测试模态框）',
			vertext: '确定版本升级？（双语测试模态框）'
		}
	}
}
