module.exports = {
	message: {
		index:{
			login:"Sign in",
			register:"Sign up",
			message:"Notify",
			tabbar:["Home","Manual","DATABASE","BLOGs","ABOUT US"]
		},
		footerBar:{
			title:"Peking University Reactive Sites for Organic Compounds Database ",
			Abbreviation:"(PKU-REOD)",
			contentus:"CONTACT US",
			content:"8600 Rockville Pike, Bethesda, MD, 20894 USA",
			type:["Contact","Policies","FOIA"],
			unit:["National Library of Medicine","National Institutes of Health","Department of Health and Human Services","USA.gov"],
			fllow:"Follow Pubchem",
			terms:["Copyright © Peking University All rights reserved","No.5 Yiheyuan Road Haidian District, Beijing, P.R.China 100871","Beijing ICP No. 05065075-1","Beijing Public Network Security No. 110402430047"]

		},
		my: {
			name:'My',
			button:'Log in',
			sentence: 'Welcome to the mypage!',
			profile:'Profile',
			exit: 'Logout',
			message:'My message',
			comment:'My comment',
			favorites:'My favorites',
			aboutus:'About us',
			contact:'Contact number',
			messagenot:'Message Notification',
			language:'Language',
			password:'Change Password',
			agreement:'User Agreement',
			signout:'Sign out',
			introduction:'No introduction!',
			submit:'Submit application',
		},
		Home:{
			name:"Home",
			title1:"Explore Chemistry",
			title2:"Quickly find chemical infomation from PKU-REOD",
			placehold:"Please enter the content keywords you need to search",
			blogs:"OUR BLOGs",
			seeall:"See More",
			learnmore:"LEARN MORE"
		},
		manual:{
			name:"Manual",
			title:"user 's manual"
		},
		database:{
			name:"DATABASE",
			placehold:"Please enter the content keywords you need to search",
			seeall:"See More"
		},
		blogs:{
			name:"BLOGs"
		},
		about:{
			name:"ABOUT US",
			title1:"ABOUT US",
			title2:"The current research interests",
			title3:"OUR Group"
		},
		search:{
			name:"Search",
			placehold:"Please enter the content keywords you need to search",
			seeall:"See More",
			interest:"You might be interested",
			nonetips:'Sorry, did not find what you were looking for'
		},
		myCollection:{
			name:"My Collection",
			to:"Collection in",
			none:"You haven't favorited yet...",
			delbtn:"Unfavorite",
			seeText:"View original text"
		},
		myComments:{
			name:'My Comment',
			to:'Comment in',
			none:"You have no comments yet...",
			seeText:"View original text"
		},
		myMessage:{
			name:'My Message',
			header:'System message',
			read:'read',
			noread:'unread',
			to:'release time',
			none: 'You have messages yet...',
			tips:'The message has been read'
		},
		myMessageInfo:{
			name:'Message Details',
			to:'to My message',
			time:'release time',	
			delete:'Delete'
		},
		mySubmit:{
			name:'Submit Application',
			to:'Payment submission',
			button:'Submit',
			authorization:'Authorization application',
			authorizatinfo:'In order for us to contact you more quickly, understand your demands, and meet your requirements, please fill in the following information carefully, ensure the accuracy of the information, and submit it.',
			username:'Your Name',
			usernametip:'Please enter your name, such as Li Li',
			telephone:'Telephone',
			telephonetip:'Please enter your telephone number',
			email:'E-mail',
			emailtip:'Please enter your E-mail',
			need:'Your need',
			needtip:'Please enter your need, such as get permission to read the full text.'
		},
		mySublist:{
			name: 'Payment Submission',
			to:'to Submit application',
			time:'Submit in',
			placeholder: 'Please enter keywords to search'
		},
		login:{
			name:'Log in',
			button:'Log in',
			typephone:'Mobile phone',
			typeemail:'E-mail',
			phonetips:'Enter your mobile phone number',
			emailtips:'Enter your E-mail',
			code:'get verification code',
			codetips:'verification code',
			pswtips:'Enter your password',
			agree:'I have read and agree to the',
			agreement:'Privacy Policy and User Agreement',
			agreetips:'Please confirm user \r\n agreement',
			forgetpw:'forget the password',
			signup:'sign up',
			verification:'verification failed'
		},
		register:{
			name:'Log up',
			button:'Log up',
			typephone:'Mobile phone',
			typeemail:'E-mail',
			usernametips:'Please enter your nickname',
			phonetips:'Enter your mobile phone number',
			emailtips:'Enter your E-mail',
			code:'get verification code',
			changecode:'seconds reacquire',
			endcode:'reacquire',
			codetips:'verification code',
			pswtips:'Enter your password',
			pswagaintips:'Enter your password again',
			pswnotips:'Password input is inconsistent',
			emailnotips:'Incorrect email address!',
			phonenotips:'The phone number is incorrect!',
			agree:'I have read and agree to the',
			agreement:'Privacy Policy and User Agreement',
			agreetips:'Please confirm user \r\n agreement',
			loginTips:'Already have an account,',
			login:'log in now',
			verification:'verification failed'
		},
		forgetpwd:{
			name:'Retrieve password',
			button:'Revise',
			typephone:'Mobile phone',
			typeemail:'E-mail',
			phonetips:'Enter your mobile phone number',
			emailtips:'Enter your E-mail',
			code:'get verification code',
			changecode:'reacquire in seconds',
			endcode:'reacquire',
			codetips:'verification code',
			pswtips:'Enter your password',
			pswagaintips:'Enter your password again',
			pswnotips:'Password input is inconsistent',
			emailnotips:'Incorrect email address!',
			phonenotips:'The phone number is incorrect!',
			verification:'verification failed',
			loginTips:'Already have an account,',
			login:'log in now',
		},
		changePswd:{
			name:'Retrieve password',
			button:'Change',
			typephone:'Mobile phone',
			typeemail:'E-mail',
			phonetips:'Enter your mobile phone number',
			emailtips:'Enter your E-mail',
			code:'get verification code',
			changecode:'reacquire in seconds',
			endcode:'reacquire',
			codetips:'verification code',
			pswtips:'Enter your password',
			pswagaintips:'Enter your password again',
			pswnotips:'Password input is inconsistent',
			emailnotips:'Incorrect email address!',
			phonenotips:'The phone number is incorrect!',
			verification:'verification failed'
		},
		detail:{
			name:'Details',
			button:'Log in',
			buttontip:'Not logged in yet, please log in first',
			applybtn:'APPLY',
			placehold:"Please enter the content keywords you need to search",
			toText:'Your current location is',
			to:['DATABASE','blogs'],
			comment:'Comment now',
			tocomment:'Comment',
			nocomment:'There are no comments yet, come be the first!',
			commenttip:'write comments here!',
			commentlist:'Featured Message',
			collection:'Collection',
			collected:'Collected',
			readmore:'Read the full text',
			fulltext:'View full text',
			loginfulltext:'Login to view',
			collecttip:'Please login first!',
			npopup:{
				content:'The information you need to view the full text is confidential, and you need to submit an application to the sender to obtain the corresponding permissions.',
				button1:'Telephone',
				button2:'Apply',
			},
		},
		// 下面的不用
		tabBar: {
			home: 'home',
			collection:'Collection',
			news:'News',
			my: 'My',
			language: 'current Language',
			name:'PKU-REOD'
		},
		cande:{
			button:'Enter Now',
			introduce1:'Peking University Reac-',
			introduce2:'tive Sites for Organic',
			introduce3:'Compounds Database',
			tips:'Please select language'
		},
		home:{
			name:'PKU-REOD',
			labeltext:'Latest information',
			list: [{
				name: 'PAHs',
				img: 'PAHs'
			}, {
				name: 'EDCs',
				img: 'EDCs'
			},{
				name: 'PPCPs',
				img: 'PPCPs'
			},{
				name: 'PFAS',
				img: 'PFAS'
			},{
				name: 'DBPs',
				img: 'DBPs'
			},{
				name: 'Other',
				img: 'Other'
			}]
		},
		collection:{
			name: 'Collection',
			labeltext:'You might be interested',
			nonetips:'Sorry, your favorites are empty!',
			nonebg:'./../../static/image/collection-none.png',	
			createtip:'Collection in'
		},
		news:{
			name: 'News',
			placeholder: 'Enter the keywords you want to search',
		},
		profile:{
			name:'My Profile',
			button:'Submit',
			avatar:'avatar',
			username:'username',
			usernametip:'Please enter your username',
			nickname:'nickname',
			nicknametip:'Please enter your nickname',
			bio:'my introduction',
			biotip:'Please enter your introduction',
			fail:'verification failed'
		},
		// search:{
		// 	name:'search',
		// 	placeholder:'Enter the keywords you want to search',
		// 	labeltext:'You might be interested',
		// 	nonetips:'Sorry, did not find what you were looking for',
		// 	nonebg:'./../../static/image/search-none.png',	
		// },
		// database:{
		// 	name:'database'
		// },
		codelogin:{
			name:'Log in',
			button:'Log in',
			typephone:'Mobile phone',
			typeemail:'E-mail',
			phonetips:'Enter your mobile phone number',
			emailtips:'Enter your E-mail',
			code:'get verification code',
			codetips:'verification code',
			pswtips:'Enter your password',
			agree:'I have read and agree to the',
			agreement:'Privacy Policy and User Agreement',
			forgetpw:'forget the password'
		},
		agreement:{
			name: 'User Agreement'
		},
		// index: {
		// 	sentence: 'Welcome to the homepage!',
		// 	saoma: 'saoma',
		// 	drawer: 'drawer',
		// 	judge: 'judge',
		// 	yes: 'Yes',
		// 	no: 'No'
		// },
		loadtext: {
			loadmore: 'Pull up gently',
			loading: 'Trying to load',
			nomore: 'Really gone'
		},
		popup: {
			open: 'Not yet open!',
			tips: 'tips',
			modal: 'This is a modal pop-up window!',
			confirm: 'confirm',
			cancel: 'cancel',
			loading: 'Loading...',
			exit: 'Exit',
			exittext: 'Are you sure you want to log out？',
			version: 'Version',
			vertext: 'Are you sure you want to upgrade？'
		}
	}
}
