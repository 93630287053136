<template>
  <div class="footerBar">
    <div class="topBox">
      <div class="top">
        <span>{{ footerData.title }}{{ footerData.Abbreviation }}</span>
      </div>
      <div class="contact">
        <span>
          <i class="el-icon-message"></i>
          <span>{{ email || "wen.liu@pku.edu.cn" }}</span>
        </span>
        <div class="line"></div>
        <span>
          <i class="el-icon-phone-outline"></i>
          <span>{{ phone || "010-62000000" }}</span>
        </span>
      </div>
      <div class="btn">
        <el-button @click="contentus">{{ footerData.contentus }}</el-button>
      </div>
    </div>
    <div class="bottomBox">
      <div class="menu">
        <div class="left_menu">
          <div class="title">
            {{ footerData.title }}
          </div>
          <div v-for="(item, index) in bottomtopinfo" :key="index">
            <div class="titleTip">
              {{ item.title }}
              <!-- {{ footerData.content }} -->
            </div>
            <div
              class="select"
              v-html="item.content"
            >
              <!-- <p>{{ footerData.type[0] }}</p>
              <p>{{ footerData.type[1] }}</p>
              <p>{{ footerData.type[2] }}</p> -->
            </div>
          </div>

          <div class="line"></div>
          <div
            class="ullist"
            v-for="(item, index) in bottominfo"
            :index="index"
            :key="item.id"
            v-html="item.content"
          >
            <p>{{ footerData.unit[0] }}</p>
            <p>{{ footerData.unit[1] }}</p>
            <p>{{ footerData.unit[2] }}</p>
            <p>{{ footerData.unit[3] }}</p>
          </div>
        </div>
        <div class="right_menu">
          <div class="title">{{ footerData.fllow }}</div>
          <div class="linkBox">
            <div class="linkItem" v-for="(item, index) in list" :key="index">
              <div class="linkImg">
                <img :src="item.image" alt="" />
              </div>

              <el-link :href="item.url" target="_blank" :underline="false">{{
                item.title
              }}</el-link>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span v-for="(item, index) in copyright" :key="index"><el-link :href="item.url" target="_blank" :underline="false">{{
                item.title
              }}</el-link></span>
        <!-- <span>{{ footerData.terms[0] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[1] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[2] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[3] }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBar",
  props: {
    followPubchemList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      bottomtopinfo: [],
      bottominfo: [],
      copyright:[],
      checkList: [
        {
          image: require("./../../public/img/home/facebook.png"),
          title: "Facebook",
          url: "https://www.facebook.com/pubchem"
        },
        {
          image: require("./../../public/img/home/twitter.png"),
          title: "Twitter",
          url: "https://www.twitter.com/pubchem"
        },
        {
          image: require("./../../public/img/home/rss.png"),
          title: "RSS",
          url: "https://pubchemblog.ncbi.nlm.nih.gov/feed/"
        },
        {
          image: require("./../../public/img/home/blog.png"),
          title: "Blog",
          url: "https://pubchemblog.ncbi.nlm.nih.gov/"
        }
      ]
    };
  },
  mounted() {
    this.getAbout();
  },
  computed: {
    footerData() {
      return this.i18n["footerBar"];
    },
    phone() {
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.phone_number;
      }
      return false;
    },
    email() { 
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.mail;
      }
      return false;
    },
    list() {
      if (this.followPubchemList.length > 0) {
        return this.followPubchemList;
      }
      return this.checkList;
    }
  },
  methods: {
    contentus(){
      this.$emit('drawerFn',true);
    },
    // 获取pcBottom信息
    async getAbout() {
      // 获取左边横排列表
      this.bottomtopinfo = await this.getblocklist("bottomtopinfo");
      // 获取左边列表
      this.bottominfo = await this.getblocklist("bottominfo");
      // 获取底部copyright
      this.copyright = await this.getblocklist("copyright");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #9b0000;
@mixin line() {
  .line {
    width: 1px;
    height: 16px;
    background-color: #ffffff;
    margin: 0 20px;
  }
}
@mixin title() {
  .title {
    font-size: 14px;
    font-weight: bold;
  }
}
.footerBar {
  font-size: 9px;
  color: #ffffff;
  .topBox {
    height: 278px;
    background-color: $vue_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
      font-size: 20px;
      font-weight: bold;
    }
    .contact {
      padding: 30px 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffffcc;
      @include line;
      span {
        display: flex;
        align-items: center;
        i {
          font-size: 24px;
          margin-right: 10px;
        }
      }
    }
    .btn {
      button {
        width: 250px;
        height: 45px;
        color: $vue_color;
        font-weight: bold;
        font-size: 15px;
        border-radius: 5px;
      }
    }
  }
  .bottomBox {
    height: 250px;
    background-color: #282828;
    display: flex;
    box-sizing: border-box;
    padding: 32px 0 10px 0;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    .menu {
      flex: 1;
      display: flex;
      width: 960px;
      justify-content: space-between;
      .left_menu {
        flex: 1;
        display: flex;
        flex-direction: column;
        @include title;
        .titleTip {
          padding: 2px 0 16px 0;
        }
        .select {
          font-size: 12px;
          font-weight: bold;
          ::v-deep p {
            margin: 0 20px 0 0;
          }
        }
        .line {
          background-color: #626262;
          margin: 14px 0;
          width: 110px;
          height: 2px;
        }
        .ullist {
          display: flex;
          flex-direction: column;
          color: #ffffffcc;
          ::v-deep p {
            margin-bottom: 6px;
          }
        }
      }
      .right_menu {
        @include title;
        .linkBox {
          font-size: 12px;
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .linkItem {
            margin-top: 10px;
            display: flex;
            .linkImg {
              margin-right: 12px;
              padding: 6px;
              border-radius: 50%;
              border: 1px solid #fff;
              display: flex;
              transition: 0.3s;
              img {
                width: 14px;
                height: 14px;
              }
            }
            .el-link.el-link--default {
              color: #ffffff;
              transition: 0.3s;
            }
          }
          .linkItem:hover .linkImg {
            background-color: #409eff;
          }
          .linkItem:hover .el-link.el-link--default {
            color: #409eff;
          }
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: center;
      span{
        border-right: 1px solid #ffffff;
        padding:0 20px;   
      }
      ::v-deep .el-link--inner{
        color: #ffffff;
      }
      span:last-child{
        border:none;
      }
      @include line;
    }
  }
}
</style>
